const chatVisibility = visibility => {
	let count = 0;
	try {
		const chat = document.getElementById("sc-support-chat");
		if (chat) {
			//const chat = document.getElementById("sc-support-chat");
			chat.style.visibility = visibility;
		} else {
			throw "e";
		}
	} catch (error) {
		if (count < 10) {
			setTimeout(() => {
				count += count;
				chatVisibility(visibility);
			}, 5000);
		} else {
			throw error;
		}
	}
};

export default {
	shutdown() {
		chatVisibility("hidden");
	},
	identify(params) {
		console.log(params)
		// Ensure the widget is available in the DOM
		/*console.log("-------->>>",params,window.MessageBirdChatWidget)

		console.log("identifyng",window.MessageBirdChatWidget)
		let _birdParams = {
			email: "dd",
			phone: ""
		}

		let toSend = {..._birdParams,...params}
		window.mbembeds.identify('123-456-789',toSend)
		//window.MessageBirdChatWidget.identify('123-456-789',toSend)
		//window.Atlas.identify(params);*/
	},
	start() {
		chatVisibility("visible");
	},
	openChat() {
		const chat = document.getElementById("sc-support-chat");
		const btn = chat.getElementsByClassName("bubble")[0];
		chat.style.visibility = "visible";
		btn.click();
		/*setTimeout(() => {
			const constrols = chat.getElementsByClassName("controls")[0];
			const btnClose = constrols.getElementsByTagName("button")[0];
			btnClose.addEventListener("click", function() {
				chat.style.visibility = "hidden";
			});
		}, 1000);*/
	}
};
